import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import Multiselect from "./Multiselect.js";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState, useRef } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import TableRowsIcon from "@mui/icons-material/TableRows";
import SingleSelect from "./SingleSelect.js";
import RangeSlider from "./RangeSlider.js";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TableRight from "./TableRight";
import { useReactToPrint } from "react-to-print";

export default function Filterpaper(props) {
  const allTags = [
    "IEP",
    "Growth Plan",
    "SSF",
    "New to Bialik (SK+)",
    "Shiluv",
    "Ulpan",
    "Anaphylaxis",
    "Asthma",
    "Serious Condition",
    "No Photo Release",
    "Ivrit MOD",
    "Tanach MOD",
    "ELL",
    "PTA Parent",
    "Divorced Household",
    "Staff Child",
    "Former Staff Child",
    "Deaf Parent",
    "Board Parent",
    "Kippah Yes",
    "Kippah No",
    "Kippah Student",
    "HT",
    "BITA",
    "BGW",
    "HU",
    ...props.allEmojis,
  ];

  const [alignment, setAlignment] = useState(props.currentView);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    props.setCurrentView(newAlignment);
  };

  const contentRef = props.contentRef;

  const [loadingReport, setLoadingReport] = useState(false);

  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint: () => {
      setLoadingReport(false);

      props.setRowsPerPage(12);
    },
  });

  function emailFiltered() {
    let filteredData = props.filteredData;
    let emailList = [];
    for (let i in filteredData) {
      emailList.push(
        filteredData[i][39].split(",").filter((x) => x.includes("@"))
      );
    }
    return emailList.join(",");
  }

  function emailFilteredStudents() {
    let filteredData = props.filteredData;
    let emailList = [];
    for (let i in filteredData) {
      filteredData[i][34] && emailList.push(filteredData[i][34]);
    }
    return emailList.join(",");
  }

  return (
    <>
      <Paper elevation={3} className="filterPaper">
        <div className="filterOptionsHead">
          <Typography variant="h6">Filter Students</Typography>

          {JSON.stringify(props.filterOptions) !=
            JSON.stringify(props.defaultFilterOptions) && (
            <Button
              variant="text"
              color="primary"
              className="resetBtnFilter"
              onClick={() => props.changeFilterOptions("resetAll")}
            >
              Reset
            </Button>
          )}
        </div>

        <Divider className="regularDivider" />
        <Typography
          variant="subtitle1"
          gutterBottom
          className="filterTopMargin"
        >
          By Name
        </Typography>

        <TextField
          id="outlined-basic"
          label="Student Name"
          variant="outlined"
          onChange={props.changeFilterOptions}
          value={props.filterOptions["studentName"]}
          sx={{ width: "100%" }}
          name="studentName"
        />

        <Typography
          variant="subtitle1"
          gutterBottom
          className="filterTopMargin"
        >
          By Class
        </Typography>
        <Multiselect
          includedClasses={props.includedClasses}
          changeFilterOptions={props.changeFilterOptions}
          filterOptions={props.filterOptions}
          sx={{ width: "100%", minWidth: "unset" }}
          compName="studentClasses"
          compId="studentClassSelect"
          compLabel="Classes"
          compLabelId="studentClassSelectLabel"
          filterOption="studentClasses"
        />

        <Typography
          variant="subtitle1"
          gutterBottom
          className="filterTopMargin"
        >
          By Tag
        </Typography>
        <Multiselect
          includedClasses={allTags}
          changeFilterOptions={props.changeFilterOptions}
          filterOptions={props.filterOptions}
          sx={{ width: "100%", minWidth: "unset" }}
          compName="studentFeature"
          compId="studentFeatureSelect"
          compLabel="Tags"
          compLabelId="studentFeatureSelectLabel"
          filterOption="studentFeature"
        />

        <Typography
          variant="subtitle1"
          gutterBottom
          className="filterTopMargin"
        >
          Exclude By Tag
        </Typography>
        <Multiselect
          includedClasses={allTags}
          changeFilterOptions={props.changeFilterOptions}
          filterOptions={props.filterOptions}
          sx={{ width: "100%", minWidth: "unset" }}
          compName="studentFeatureEx"
          compId="studentFeatureSelectEx"
          compLabel="Tags"
          compLabelId="studentFeatureSelectLabelEx"
          filterOption="studentFeatureEx"
        />

        {props.includedTerms.length > 0 && (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom
              className="filterTopMargin"
            >
              By Marks
            </Typography>
            <SingleSelect
              filterOptions={props.filterOptions}
              setFilterOptions={props.changeFilterOptions}
              options={props.includedTerms}
              labelId="gradeSelectLabel"
              compId="gradeSelectId"
              compLabel="Subject"
              compName="Term"
            />
            <RangeSlider
              filterOptions={props.filterOptions}
              setFilterOptions={props.changeFilterOptions}
            />
          </>
        )}

        <div className="viewSelects">
          <Typography
            variant="subtitle1"
            gutterBottom
            className="filterTopMargin"
          >
            Select View
          </Typography>

          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="select view"
            className="centerPagination"
            sx={{ width: "100%" }}
          >
            <ToggleButton
              sx={{ width: "50%" }}
              value="grid"
              aria-label="Grid View"
            >
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton
              sx={{ width: "50%" }}
              value="table"
              aria-label="Table View"
            >
              <TableRowsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <Typography
          variant="subtitle1"
          gutterBottom
          className="filterTopMargin"
        >
          Sort By
        </Typography>
        <SingleSelect
          filterOptions={props.filterOptions}
          setFilterOptions={props.changeFilterOptions}
          options={["Class", "First Name", "Last Name", "Birthdate"]}
          labelId="sortLabel"
          compId="sortId"
          compLabel="Sort"
          compName="sortBy"
        />

        {/*<Button
          variant="contained"
          color="primary"
          sx={{ width: "100%", marginTop: "20px" }}
          onClick={() => {
            setLoadingReport(true);
            props.setRowsPerPage(5000);
            props.setCurrentView("table");
            setAlignment("table");

            setTimeout(() => {
              reactToPrintFn();
            }, 500);
          }}
        >
          {loadingReport ? "Loading..." : "Generate Report"}
        </Button>*/}

        {JSON.stringify(props.filterOptions) !=
          JSON.stringify(props.defaultFilterOptions) &&
          emailFiltered().length > 0 && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={() => window.open(`mailto:?bcc=${emailFiltered()}`)}
            >
              Email All Filtered Parents
            </Button>
          )}

        {JSON.stringify(props.filterOptions) !=
          JSON.stringify(props.defaultFilterOptions) &&
          emailFilteredStudents().length > 0 && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={() =>
                window.open(`mailto:?bcc=${emailFilteredStudents()}`)
              }
            >
              Email All Filtered Students
            </Button>
          )}

        <Divider className="regularDivider bottomDivider" />
        <Button variant="contained" color="error" onClick={props.logoutFn}>
          <LogoutIcon className="googleLoginIcon" /> Sign Out
        </Button>
      </Paper>
    </>
  );
}
